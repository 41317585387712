import { FunctionComponent } from "react";
import FrameComponent3 from "../components/FrameComponent3";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent from "../components/FrameComponent";

const MacBookPro14: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-deepskyblue overflow-hidden flex flex-col items-end justify-start pt-6 px-0 pb-0 box-border gap-[181px] tracking-[normal] mq450:gap-[45px_181px] mq925:gap-[90px_181px]">
      <FrameComponent3 />
      <FrameComponent2 />
      <FrameComponent1 />
      <FrameComponent />
    </div>
  );
};

export default MacBookPro14;
