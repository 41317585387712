import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type UnlockCardType = {
  stackedAreaUp?: string;
  title?: string;
  description?: string;

  /** Style props */
  propBackgroundColor?: CSSProperties["backgroundColor"];
};

const UnlockCard: FunctionComponent<UnlockCardType> = ({
  stackedAreaUp,
  title,
  description,
  propBackgroundColor,
}) => {
  const unlockCardStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  return (
    <div
      className="flex-1 rounded-3xl bg-default-indigo-50 flex flex-col items-start justify-start pt-8 px-8 pb-[106px] box-border gap-[48px] min-w-[319px] max-w-full text-left text-13xl text-black font-sf-pro-display mq700:pt-[21px] mq700:pb-[69px] mq700:box-border mq450:gap-[24px_48px]"
      style={unlockCardStyle}
    >
      <img
        className="w-16 h-16 relative overflow-hidden shrink-0"
        loading="lazy"
        alt=""
        src={stackedAreaUp}
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
        <b className="self-stretch relative leading-[125%] mq450:text-lgi mq450:leading-[24px] mq925:text-7xl mq925:leading-[32px]">
          {title}
        </b>
        <div className="self-stretch relative text-5xl leading-[150%] text-default-gray-500 mq450:text-lgi mq450:leading-[29px]">
          {description}
        </div>
      </div>
    </div>
  );
};

export default UnlockCard;
