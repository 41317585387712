import { FunctionComponent } from "react";

const FrameComponent: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[547px] relative max-w-full text-left text-5xl text-default-gray-900 font-match mq450:h-auto mq450:min-h-[547]">
      <div className="absolute top-[0px] left-[0px] bg-deepskyblue w-full flex flex-col items-center justify-start py-24 px-5 box-border gap-[48px] max-w-full h-full mq700:gap-[24px_48px]">
        <div className="w-[1120px] flex flex-row items-center justify-between gap-[20px] max-w-full mq450:flex-wrap">
          <img
            className="h-[42px] w-[42px] relative"
            alt=""
            src="/ch4-logo.svg"
          />
          <button className="cursor-pointer [border:none] py-3 px-4 bg-default-gray-900 rounded flex flex-row items-center justify-center whitespace-nowrap hover:bg-darkslategray">
            <b className="w-[94px] relative text-lg inline-block font-match text-default-white text-center min-w-[94px]">
              We’re hiring!
            </b>
          </button>
        </div>
        <div className="w-[1120px] bg-deepskyblue shadow-[0px_-1px_0px_rgba(0,_0,_0,_0.04)] flex flex-row items-start justify-center py-12 px-0 box-border gap-[32px] max-w-full mq700:gap-[32px_16px] mq925:flex-wrap">
          <div className="w-[448px] flex flex-col items-start justify-start py-0 pr-24 pl-0 box-border gap-[24px] min-w-[448px] max-w-full mq700:min-w-full mq925:flex-1 mq925:pr-0 mq925:box-border">
            <div className="self-stretch relative leading-[150%] font-medium mq450:text-lgi mq450:leading-[29px]">{`Achieving Net-Zero Methane Emissions for Oil & Gas`}</div>
            <div className="self-stretch relative text-sm leading-[150%] text-primary">
              Made with care.
            </div>
            <div className="flex flex-row items-start justify-start gap-[24px]">
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                loading="lazy"
                alt=""
                src="/socialtwitter.svg"
              />
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                loading="lazy"
                alt=""
                src="/sociallinkedin.svg"
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[16px] min-w-[416px] max-w-full text-base mq700:min-w-full">
            <div className="self-stretch relative leading-[150%] font-medium">
              About Ch4mber
            </div>
            <div className="self-stretch flex flex-col items-start justify-start text-sm text-primary">
              <div className="self-stretch relative leading-[150%]">
                Coming Soon.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[217px] [backdrop-filter:blur(4px)] box-border w-[1037px] h-px border-t-[1px] border-solid border-gray-200" />
    </section>
  );
};

export default FrameComponent;
