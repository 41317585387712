import { FunctionComponent } from "react";
import UnlockCard from "./UnlockCard";

const FrameComponent1: FunctionComponent = () => {
  return (
    <section className="w-[1498px] flex flex-row items-start justify-center pt-0 px-5 pb-[37px] box-border max-w-full text-left text-13xl text-black font-sf-pro-display">
      <div className="w-[1322px] flex flex-row flex-wrap items-start justify-center gap-[23px] max-w-full">
        <UnlockCard
          stackedAreaUp="/stacked-area-up.svg"
          title="Methane Testing, Quantification, and Monitoring"
          description="Leak detection and repair programs, installing vapor recovery units, replacing pumps and compressor seals, and replacing existing devices with instrument air or electric motor systems."
        />
        <div className="flex-1 rounded-3xl bg-extended-cyan-50 flex flex-col items-start justify-start pt-8 px-8 pb-[250px] box-border gap-[48px] min-w-[319px] max-w-full mq700:pt-[21px] mq700:pb-[162px] mq700:box-border mq450:gap-[24px_48px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/lightning.svg"
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="self-stretch h-[120px] relative leading-[125%] inline-block mq450:text-lgi mq450:leading-[24px] mq925:text-7xl mq925:leading-[32px]">
              <p className="m-0">Asset Retirement and Methane Abatement</p>
            </b>
            <div className="self-stretch relative text-5xl leading-[150%] text-default-gray-500 mq450:text-lgi mq450:leading-[29px]">{`Properly decommissioning end-of-life oil & gas wells`}</div>
          </div>
        </div>
        <UnlockCard
          stackedAreaUp="/circular-02.svg"
          title="Carbon Credit Generation"
          description={`We own and operate well decommissioning projects, which generate carbon credits. We sell these to Oil & Gas Cos.`}
          propBackgroundColor="#f9fafb"
        />
      </div>
    </section>
  );
};

export default FrameComponent1;
