import { FunctionComponent } from "react";

const GroupComponent: FunctionComponent = () => {
  return (
    <div className="w-[960px] flex flex-row flex-wrap items-start justify-start pt-[34px] px-0 pb-[33px] box-border gap-[56px] max-w-full mq700:gap-[28px]">
      <img
        className="h-[134px] w-[200px] relative"
        loading="lazy"
        alt=""
        src="/component-2.svg"
      />
      <div className="flex-1 flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border min-w-[458px] max-w-full mq700:min-w-full">
        <img
          className="self-stretch h-24 relative max-w-full overflow-hidden shrink-0"
          loading="lazy"
          alt=""
          src="/frame.svg"
        />
      </div>
    </div>
  );
};

export default GroupComponent;
