import { FunctionComponent } from "react";
import GroupComponent from "./GroupComponent";

const FrameComponent3: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
      <div className="w-[1216px] flex flex-col items-start justify-start gap-[82px] max-w-full mq700:gap-[41px_82px] mq450:gap-[20px_82px]">
        <header className="self-stretch rounded-3xl flex flex-row items-center justify-between p-6 top-[0] z-[99] sticky gap-[20px] text-center text-lg text-default-gray-900 font-match border-[1px] border-solid border-gray-100">
          <div className="flex flex-row items-center justify-start">
            <div className="flex flex-row items-center justify-start">
              <div className="h-16 w-16 rounded-81xl bg-ghostwhite flex flex-row items-center justify-center pt-[5.300000000000182px] px-[8.5px] pb-[5.199999999999818px] box-border">
                <img
                  className="h-[53.5px] w-[47px] relative"
                  loading="lazy"
                  alt=""
                  src="/vector.svg"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[12px]">
            <div className="rounded bg-default-white flex flex-row items-center justify-center py-2 px-[15px] whitespace-nowrap border-[1px] border-solid border-default-gray-300">
              <b className="w-[92px] relative inline-block min-w-[92px]">
                Chat with us
              </b>
            </div>
            <div className="rounded bg-default-gray-900 hidden flex-row items-center justify-center py-2 px-4 whitespace-nowrap text-default-white">
              <b className="relative">Sign up</b>
            </div>
          </div>
        </header>
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
          <GroupComponent />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent3;
