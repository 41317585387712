import { FunctionComponent } from "react";

const FrameComponent2: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-8 box-border max-w-full text-center text-45xl text-default-gray-900 font-match">
      <div className="w-[1120px] flex flex-col items-center justify-start gap-[40px] max-w-full mq700:gap-[20px_40px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <h1 className="m-0 self-stretch relative text-inherit tracking-[-0.02em] leading-[150%] font-medium font-inherit mq450:text-19xl mq450:leading-[58px] mq925:text-32xl mq925:leading-[77px]">
            Methane Mitigation on a Global Scale
          </h1>
          <div className="self-stretch relative text-5xl leading-[150%] text-extended-light-blue-900 mq450:text-lgi mq450:leading-[29px]">
            Methane is responsible for around 30% of the current rise in global
            temperature. Join us in our fight to end methane emissions.
          </div>
        </div>
        <div className="w-[775px] flex flex-row flex-wrap items-start justify-center gap-[40px] max-w-full mq450:gap-[20px]">
          <div className="flex-1 rounded-md bg-default-gray-50 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] box-border overflow-hidden flex flex-row items-center justify-start py-[26.5px] px-[15px] min-w-[238px] max-w-full border-[1px] border-solid border-primary">
            <input
              className="w-full [border:none] [outline:none] font-medium font-text-base-leading-6-font-medium text-base bg-[transparent] h-6 flex-1 relative leading-[24px] text-default-gray-800 text-left inline-block min-w-[200px] max-w-full p-0"
              placeholder="Enter your email"
              type="text"
            />
          </div>
          <button className="cursor-pointer [border:none] py-6 px-16 bg-default-gray-900 rounded-xl flex flex-row items-center justify-center box-border whitespace-nowrap max-w-full hover:bg-darkslategray mq450:pl-5 mq450:pr-5 mq450:box-border">
            <b className="w-[241px] relative text-5xl inline-block font-match text-default-white text-center">
              Let’s chat for 15 minurtes
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent2;
